import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Modal,
  Upload,
  Skeleton,
  Result,
  Row,
  Col
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  InboxOutlined
} from "@ant-design/icons";
import { TourApi } from "../../Data/type";
import { createTour } from "../../Data/apis";
import { useState } from "react";
import app, { homeData } from "../../Data/firebase";
import confirm from "antd/lib/modal/confirm";

/*

  city: string;
  email?: string;
  details: {
    poster: string;
    title: string;
    discription: string;
    pdf?: [string, string]; // show, url
  };
  totalRooms: number;
  main: {
    venue: {
      address: string[];
      coordinates: { latitude: number; longitude: number };
    };
    ageRec: string;
    deadline: string;
    timeline: [string, string]; // start-end
  };
  prizePool: [string, string][]; // name, price
  info: {
    regestrationFee: number;
    playersPerTeam: number;
    substituteAllowed: number;
    type: string;
    durationPerMatch: number;
    dates: {
      semiFinal: string; // yyyy-dd-mm
      final: string; // yyyy-dd-mm
    };
  };

*/

const CreateTour: React.FC<{
  getJWT: () => Promise<string>;
  home: homeData;
}> = ({ getJWT, home }) => {
  const [photoUrl, setPhotoUrl] = useState("");
  const [show, setShow] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [show1, setShow1] = useState(false);
  if (!home || home.type === "loading") return <Skeleton />;
  if (home.type === "error")
    return (
      <Result
        status="error"
        title={home.error.code}
        subTitle={home.error.message}
      >
        <Button onClick={window.location.reload}>Refresh</Button>
      </Result>
    );

  return (
    <Form
      name="basic"
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={async (a: {
        email: string;
        city: string;
        title: string;
        discription: string;
        "pdf-show": string;
        deadLine: moment.Moment;
        "address-l1": string;
        "address-l2": string;
        "address-l3": string;
        latitude: number;
        longitude: number;
        total: number;
        prizePool: {
          type: string;
          prize: string;
        }[];
        room1: {
          ageRec: string;
          fee: number;
          max: number;
        }[];
        room2: {
          ageRec: string;
          fee: number;
          max: number;
        }[];
        fee: number;
        duration: number;
        type: string;
        ageRec: string;
        timeline: [moment.Moment, moment.Moment];
        playersPerTeam: number;
        substituteAllowed: number;
        semiFinal: moment.Moment;
        final: moment.Moment;
        terms: string;
        p1name: string;
        p1email: string;
        p1num: number;
        p2name: string;
        p2email: string;
        p2num: number;
      }) => {
        const data: TourApi.create = JSON.parse(
          JSON.stringify({
            rooms: {
              total: a.total,
              totalReg: [],
              1: (a.room1 ?? []).reduce((obj, { ageRec, fee, max }) => {
                obj[ageRec] = { fee, max, regestered: [] };
                return obj;
              }, {} as any),
              2: (a.room2 ?? []).reduce((obj, { ageRec, fee, max }) => {
                obj[ageRec] = { fee, max, regestered: [] };
                return obj;
              }, {} as any)
            },
            city: a.city,
            free: !(
              Object.values(a.room1).findIndex(({ fee }) => fee !== 1) + 1 ||
              Object.values(a.room2).findIndex(({ fee }) => fee !== 1) + 1
            ),
            details: {
              discription: a.discription,
              title: a.title,
              pdf: [a["pdf-show"], pdfUrl],
              poster: photoUrl,
              terms: a.terms
            },
            contact: {
              1: {
                email: a.p1email,
                name: a.p1name,
                num: a.p1num
              },
              2: {
                email: a.p2email,
                name: a.p2name,
                num: a.p2num
              }
            },
            info: {
              type: a.type,
              playersPerTeam: a.playersPerTeam,
              substituteAllowed: a.substituteAllowed,
              durationPerMatch: a.duration,
              dates: {
                semiFinal: a.semiFinal.format("YYYY-MM-DD"),
                final: a.final.format("YYYY-MM-DD")
              }
            },
            main: {
              ageRec: a.ageRec,
              deadline: a.deadLine.format("YYYY-MM-DD"),
              venue: {
                address: [a["address-l1"], a["address-l2"], a["address-l3"]],
                coordinates: { latitude: a.latitude, longitude: a.longitude }
              },
              timeline: [
                a.timeline[0].format("YYYY-MM-DD"),
                a.timeline[1].format("YYYY-MM-DD")
              ]
            },
            prizePool: (a.prizePool ?? []).map(({ type, prize }) => [
              type,
              prize
            ]),
            email: a.email
          })
        );
        a["address-l3"] || data.main.venue.address.pop();
        (!a["pdf-show"] || !pdfUrl) && delete data.details.pdf;
        confirm({
          title: "Sure you want to do this?",
          async onOk() {
            createTour(await getJWT(), data);
          },
          okText: "Yes"
        });
      }}
      onReset={() => {
        setPdfUrl("");
        setPhotoUrl("");
      }}
    >
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="reset">
          Reset
        </Button>
      </Form.Item>
      <h1>Singles</h1>
      <Form.List name="room1">
        {(fields, { add, remove }) => {
          return (
            <Card>
              <Space direction="vertical">
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space align="baseline" key={key}>
                    <Form.Item
                      label="Age Category ( Eg: Under 15 )"
                      {...restField}
                      name={[name, "ageRec"]}
                      fieldKey={[fieldKey, "ageRec"]}
                      rules={[
                        { required: true, message: "" },
                        {
                          validator(_, value: string) {
                            if (value.indexOf(".") === -1) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('can\'t have a "." in this field')
                            );
                          }
                        }
                      ]}
                    >
                      <Input style={{ width: "10vw" }} placeholder="For" />
                    </Form.Item>
                    <Form.Item
                      label="Category Fee"
                      {...restField}
                      name={[name, "fee"]}
                      fieldKey={[fieldKey, "fee"]}
                      rules={[{ required: true, message: "" }]}
                    >
                      <InputNumber
                        style={{ width: "10vw" }}
                        placeholder="Price"
                      />
                    </Form.Item>
                    <Form.Item
                      label="--Max"
                      {...restField}
                      name={[name, "max"]}
                      fieldKey={[fieldKey, "max"]}
                      rules={[{ required: true, message: "" }]}
                    >
                      <InputNumber
                        style={{ width: "10vw" }}
                        placeholder="Max"
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    style={{ width: 200 }}
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </Space>
            </Card>
          );
        }}
      </Form.List>
      <div style={{ height: 20 }} />
      <h1>Doubles</h1>
      <Form.List name="room2">
        {(fields, { add, remove }) => {
          return (
            <Card>
              <Space direction="vertical">
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space align="baseline" key={key}>
                    <Form.Item
                      label="Age Category ( Eg: Under 15 )"
                      {...restField}
                      name={[name, "ageRec"]}
                      fieldKey={[fieldKey, "ageRec"]}
                      rules={[
                        { required: true, message: "" },
                        {
                          validator(_, value: string) {
                            if (value.indexOf(".") === -1) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('can\'t have a "." in this field')
                            );
                          }
                        }
                      ]}
                    >
                      <Input style={{ width: "10vw" }} placeholder="For" />
                    </Form.Item>
                    <Form.Item
                      label="Category Fee"
                      {...restField}
                      name={[name, "fee"]}
                      fieldKey={[fieldKey, "fee"]}
                      rules={[{ required: true, message: "" }]}
                    >
                      <InputNumber
                        style={{ width: "10vw" }}
                        placeholder="Price"
                      />
                    </Form.Item>
                    <Form.Item
                      label="--Max"
                      {...restField}
                      name={[name, "max"]}
                      fieldKey={[fieldKey, "max"]}
                      rules={[{ required: true, message: "" }]}
                    >
                      <InputNumber
                        style={{ width: "10vw" }}
                        placeholder="Max"
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    style={{ width: 200 }}
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </Space>
            </Card>
          );
        }}
      </Form.List>
      <div style={{ height: 20 }} />
      <Form.Item
        label="Tournament Email Id"
        name="email"
        rules={[
          { required: true, message: "Please input org. email" },
          { type: "email", message: "Enter a valid Email" }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Tournament City"
        name="city"
        rules={[{ required: true, message: "Please select a city" }]}
      >
        <Select>
          {Object.entries(home.data.city)
            .sort(([, { tour: a }], [, { tour: b }]) => b.length - a.length)
            .map(([city, { tour }]) => (
              <Select.Option value={city} key={city}>
                <Row>
                  <Col span={8}>{city}</Col>
                  <Col span={8}>{tour.length}</Col>
                </Row>
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Space>
        {photoUrl ? (
          <Button onClick={() => setPhotoUrl("")} danger>
            Remove Image
          </Button>
        ) : (
          <Button onClick={() => setShow(true)}>Upload Tournament Image</Button>
        )}
        <Input readOnly value={photoUrl} style={{ width: 700 }} />
        <Modal
          destroyOnClose
          visible={show}
          onCancel={() => setShow(false)}
          footer={null}
        >
          <Upload.Dragger
            maxCount={1}
            name="file"
            customRequest={async file => {
              const { floor, random } = Math;
              let result = "";
              for (let i = 0; i < 15; i++)
                result += "qwertyuiopasdfghjklzxcvbnm7896341520ZXCVBNMASDFGHJKLPOIUYTREWQ".charAt(
                  floor(random() * 62)
                );
              const x_1 = await app
                .storage()
                .ref("tour/" + result)
                .put(file.file as Blob);
              setPhotoUrl(await x_1.ref.getDownloadURL());
              setShow(false);
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
        </Modal>
      </Space>
      <div style={{ height: 20 }} />
      <Form.Item
        label="Name of Tournament"
        name="title"
        rules={[{ required: true, message: "Please input Title" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Discription Of Tournament"
        name="discription"
        rules={[{ required: true }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Terms & Condition's Of the Tournament" name="terms" rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        label="Tournament Organiser Name-1"
        rules={[{ required: true }]}
        name="p1name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Tournament Organiser Email-1"
        rules={[{ required: true }]}
        name="p1email"
      >
        <Input />
      </Form.Item>
      <Form.Item label="Tournament Organiser Ph Number-1" rules={[{ required: true }]} name="p1num">
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="Tournament Organiser Name-2"
        rules={[{ required: true }]}
        name="p2name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Tournament Organiser Email-2"
        rules={[{ required: true }]}
        name="p2email"
      >
        <Input />
      </Form.Item>
      <Form.Item label="Tournament Organiser Ph Number-2" rules={[{ required: true }]} name="p2num">
        <InputNumber />
      </Form.Item>

      <Space>
        {pdfUrl ? (
          <Button onClick={() => setPdfUrl("")} danger>
            Remove Pdf
          </Button>
        ) : (
          <Button onClick={() => setShow1(true)}>Uplode Terms & Conditions PDF</Button>
        )}
        <Input readOnly value={pdfUrl} style={{ width: 700 }} />
        <Modal
          destroyOnClose
          visible={show1}
          onCancel={() => setShow1(false)}
          footer={null}
        >
          <Upload.Dragger
            maxCount={1}
            name="file"
            customRequest={async file => {
              const { floor, random } = Math;
              let result = "";
              for (let i = 0; i < 15; i++)
                result += "qwertyuiopasdfghjklzxcvbnm7896341520ZXCVBNMASDFGHJKLPOIUYTREWQ".charAt(
                  floor(random() * 62)
                );
              const x_1 = await app
                .storage()
                .ref("tour/" + result)
                .put(file.file as Blob);
              setPdfUrl(await x_1.ref.getDownloadURL());
              setShow1(false);
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
        </Modal>
      </Space>
      <div style={{ height: 20 }} />
      {pdfUrl && (
        <Form.Item
          rules={[{ required: true }]}
          label="Write Download Terms & Conditions"
          name="pdf-show"
        >
          <Input />
        </Form.Item>
      )}
      <Form.Item label="Tournament Booking Deadline" name="deadLine" rules={[{ required: true }]}>
        <DatePicker format="YYYY-MM-DD" />
      </Form.Item>
      <Form.Item label="Age Group ( Eg: U18-U20 )" name="ageRec" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Tournament Timeine ( Eg : 30th-31st )" name="timeline" rules={[{ required: true }]}>
        <DatePicker.RangePicker />
      </Form.Item>
      <Form.Item
        label="Tournament Venue/Address Line 1"
        name="address-l1"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Tournament Venue/Address Line 2"
        name="address-l2"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Tournament Venue/Address Line 3" name="address-l3">
        <Input />
      </Form.Item>
      <Form.Item name="latitude" label="(Google Map -Latitude( Get from Google Maps))" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="longitude"
        label="(Google Map -Longitude( Get from Google Maps))"
        rules={[{ required: true }]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item label="Total Number Of Teams" name="total" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <h1>Tournament Prize Pool</h1>
      <Form.List name="prizePool">
        {(fields, { add, remove }) => {
          return (
            <Card>
              <Space direction="vertical">
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space align="baseline" key={key}>
                    <Form.Item
                      label="Category( Eg: Under15 )"
                      {...restField}
                      name={[name, "type"]}
                      fieldKey={[fieldKey, "type"]}
                      rules={[{ required: true, message: "" }]}
                    >
                      <Input style={{ width: "10vw" }} placeholder="For" />
                    </Form.Item>
                    <Form.Item
                      label="Prize"
                      {...restField}
                      name={[name, "prize"]}
                      fieldKey={[fieldKey, "prize"]}
                      rules={[{ required: true, message: "" }]}
                    >
                      <Input style={{ width: "10vw" }} placeholder="Price" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    style={{ width: 200 }}
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </Space>
            </Card>
          );
        }}
      </Form.List>
      <div style={{ height: "5vh" }} />
      {/* <Form.Item label="fee" name="fee" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item> */}
      <Form.Item label="Duration per Match(In Minutes)" name="duration" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item label="Tournament type" name="type" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Players Per Team"
        name="playersPerTeam"
        rules={[{ required: true }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label="Number Of Substitute Allowed"
        name="substituteAllowed"
        rules={[{ required: true }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label="Semi-Final Date"
        name="semiFinal"
        rules={[{ required: true }]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item label="Date of final" name="final" rules={[{ required: true }]}>
        <DatePicker />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button
          disabled={!photoUrl || !pdfUrl}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
export default CreateTour;
