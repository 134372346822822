import { Alert, Divider, Input, Row, Form, Button } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import app from "../Data/firebase";

const Login: React.FC<{ err: undefined | { code: string; message: string } }> =
  ({ err }) => {
    const [error, setError] = useState<
      undefined | { code: string; message: string }
    >(undefined);
    const [loading, setLoading] = useState(false);
    const onFinish = (value: { email: string; password: string }) => {
      setLoading(true);
      app
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .catch((err) => {
          setLoading(false);
          if (typeof err.code === "string" && typeof err.message === "string")
            setError(err);
          else setError({ code: "Some error occured", message: `${err}` });
        });
    };
    useEffect(() => {
      setError(undefined);
      setLoading(false);
    }, [err]);
    return (
      <>
        <Divider>
          <h1>Log In</h1>
        </Divider>
        {error && (
          <>
            <Row justify="center" style={{ width: "100vw" }}>
              <Alert
                style={{ width: "70vw" }}
                message={error.code}
                description={error.message}
                type="error"
              />
            </Row>
            <br />
          </>
        )}
        {err && (
          <>
            <Row justify="center" style={{ width: "100vw" }}>
              <Alert
                style={{ width: "70vw" }}
                message={err.code}
                description={err.message}
                type="error"
              />
            </Row>
            <br />
          </>
        )}
        <Row justify="center">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            style={{ width: "50vw" }}
          >
            <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 7, span: 10 }}>
              <Button
                block
                shape="round"
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </>
    );
  };
export default Login;
