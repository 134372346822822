import React, { useEffect, useState } from 'react';
import { fetchCareer, state } from "../Data/firebase";
import JsonView from "react-json-view";

interface StateProperties {
    id: string;
    data: {
        fullName: string;
        contactNumber: string;
        emailID: string;
        cityOfResidence: string;
        department: string;
        cvUrl: string;
        portfolioURL: string;
    };
}

const Careers = () => {

    const [data, setData] = useState<Array<{
        id: string;
        data: {
            fullName: string;
            contactNumber: string;
            emailID: string;
            cityOfResidence: string;
            department: string;
            cvUrl: string;
            portfolioURL: string;
        };
    }>>();

    useEffect(() => {
        fetchCareer().then(response => {
            setData(response);
        }).catch(err => console.log(err));
    }, [])
    return (
        <>
            {data ? data.map(res => <JsonView theme="apathy" src={res} />) : ""}

        </>
    );
}

export default Careers;