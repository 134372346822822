import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Modal,
  Upload,
  Skeleton,
  Result,
  Row,
  Col,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { FifaApi } from "../../Data/type";
import { createFifa } from "../../Data/apis";
import { useState } from "react";
import app, { homeData } from "../../Data/firebase";
import confirm from "antd/lib/modal/confirm";

/*

  email?: string;
  city: string;
  poster: string;
  details: {
    title: string;
    discription: string;
    pdf?: [string, string]; // show, url
    terms: string;
  };
  contact: {
    1: { email: string; num: number; name: string };
    2: { email: string; num: number; name: string };
  };
  main: {
    gameDate: string;
    deadLine: string;
    venue: {
      address: Array<string>;
      coordinates: { latitude: number; longitude: number };
    };
  };
  totalRoom: number;
  prizePool: [string, string][]; // name, price
  info: {
    regestrationFee: number;
    time: string;
    mode: string;
  };

*/

const CreateFifa: React.FC<{ getJWT: () => Promise<string>; home: homeData }> =
  ({ getJWT, home }) => {
    const [photoUrl, setPhotoUrl] = useState("");
    const [show, setShow] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");
    const [show1, setShow1] = useState(false);
    if (!home || home.type === "loading") return <Skeleton />;
    if (home.type === "error")
      return (
        <Result
          status="error"
          title={home.error.code}
          subTitle={home.error.message}
        >
          <Button onClick={window.location.reload}>Refresh</Button>
        </Result>
      );

    return (
      <Form
        name="basic"
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={async (a: {
          email: string;
          city: string;
          title: string;
          discription: string;
          "pdf-show": string;
          gameDate: moment.Moment;
          deadLine: moment.Moment;
          "address-l1": string;
          "address-l2": string;
          "address-l3": string;
          latitude: number;
          longitude: number;
          total: number;
          prizePool: {
            type: string;
            prize: string;
          }[];
          fee: number;
          time: string;
          mode: string;
          terms: string;
          p1name: string;
          p1email: string;
          p1num: number;
          p2name: string;
          p2email: string;
          p2num: number;
        }) => {
          const data: FifaApi.create = JSON.parse(
            JSON.stringify({
              city: a.city,
              details: {
                discription: a.discription,
                title: a.title,
                pdf: [a["pdf-show"], pdfUrl],
                terms: a.terms,
              },
              contact: {
                1: {
                  email: a.p1email,
                  name: a.p1name,
                  num: a.p1num,
                },
                2: {
                  email: a.p2email,
                  name: a.p2name,
                  num: a.p2num,
                },
              },
              free: a.fee===1,
              info: { mode: a.mode, regestrationFee: a.fee, time: a.time },
              main: {
                deadLine: a.deadLine.format("YYYY-MM-DD"),
                gameDate: a.gameDate.format("YYYY-MM-DD"),
                venue: {
                  address: [a["address-l1"], a["address-l2"], a["address-l3"]],
                  coordinates: { latitude: a.latitude, longitude: a.longitude },
                },
              },
              poster: photoUrl,
              prizePool: (a.prizePool ?? []).map(({ type, prize }) => [
                type,
                prize,
              ]),
              totalRoom: a.total,
              email: a.email,
            })
          );
          a["address-l3"] || data.main.venue.address.pop();
          (!a["pdf-show"] || !pdfUrl) && delete data.details.pdf;
          confirm({
            title: "Sure you want to do this?",
            async onOk() {
              createFifa(await getJWT(), data);
            },
            okText: "Yes",
          });
        }}
        onReset={() => {
          setPdfUrl("");
          setPhotoUrl("");
        }}
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="reset">
            Reset
          </Button>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please input org. email" },
            { type: "email", message: "Enter a valid Email" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: "Please select a city" }]}
        >
          <Select>
            {Object.entries(home.data.city)
              .sort(([, { fifa: a }], [, { fifa: b }]) => b.length - a.length)
              .map(([city, { fifa }]) => (
                <Select.Option value={city} key={city}>
                  <Row>
                    <Col span={8}>{city}</Col>
                    <Col span={8}>{fifa.length}</Col>
                  </Row>
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Space>
          {photoUrl ? (
            <Button onClick={() => setPhotoUrl("")} danger>
              Remove Image
            </Button>
          ) : (
            <Button onClick={() => setShow(true)}>Uplode Image</Button>
          )}
          <Input readOnly value={photoUrl} style={{ width: 700 }} />
          <Modal
            destroyOnClose
            visible={show}
            onCancel={() => setShow(false)}
            footer={null}
          >
            <Upload.Dragger
              maxCount={1}
              name="file"
              customRequest={async (file) => {
                const { floor, random } = Math;
                let result = "";
                for (let i = 0; i < 15; i++)
                  result +=
                    "qwertyuiopasdfghjklzxcvbnm7896341520ZXCVBNMASDFGHJKLPOIUYTREWQ".charAt(
                      floor(random() * 62)
                    );
                const x_1 = await app
                  .storage()
                  .ref("fifa/" + result)
                  .put(file.file as Blob);
                setPhotoUrl(await x_1.ref.getDownloadURL());
                setShow(false);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Modal>
        </Space>
        <div style={{ height: 20 }} />

        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input Title" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="discription"
          name="discription"
          rules={[{ required: true }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="terms" name="terms" rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="person1-name"
          rules={[{ required: true }]}
          name="p1name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="person1-email"
          rules={[{ required: true }]}
          name="p1email"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="person1-num"
          name="p1num"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="person2-name"
          name="p2name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="person2-email"
          name="p2email"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="person2-num"
          name="p2num"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>

        <Space>
          {pdfUrl ? (
            <Button onClick={() => setPdfUrl("")} danger>
              Remove Pdf
            </Button>
          ) : (
            <Button onClick={() => setShow1(true)}>Uplode PDF</Button>
          )}
          <Input readOnly value={pdfUrl} style={{ width: 700 }} />
          <Modal
            destroyOnClose
            visible={show1}
            onCancel={() => setShow1(false)}
            footer={null}
          >
            <Upload.Dragger
              maxCount={1}
              name="file"
              customRequest={async (file) => {
                const { floor, random } = Math;
                let result = "";
                for (let i = 0; i < 15; i++)
                  result +=
                    "qwertyuiopasdfghjklzxcvbnm7896341520ZXCVBNMASDFGHJKLPOIUYTREWQ".charAt(
                      floor(random() * 62)
                    );
                const x_1 = await app
                  .storage()
                  .ref("fifa/" + result)
                  .put(file.file as Blob);
                setPdfUrl(await x_1.ref.getDownloadURL());
                setShow1(false);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Modal>
        </Space>
        <div style={{ height: 20 }} />

        {pdfUrl && (
          <Form.Item
            rules={[{ required: true }]}
            label="pdf-show"
            name="pdf-show"
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item
          label="gameDate"
          name="gameDate"
          rules={[{ required: true }]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>

        <Form.Item
          label="deadLine"
          name="deadLine"
          rules={[{ required: true }]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>

        <Form.Item
          label="Address-l1"
          name="address-l1"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Address-l2"
          name="address-l2"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Address-l3" name="address-l3">
          <Input />
        </Form.Item>

        <Form.Item
          name="latitude"
          label="latitude"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="longitude"
          label="longitude"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item label="total" name="total" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.List name="prizePool">
          {(fields, { add, remove }) => {
            return (
              <Card>
                <Space direction="vertical">
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space align="baseline" key={key}>
                      <Form.Item
                        label="For"
                        {...restField}
                        name={[name, "type"]}
                        fieldKey={[fieldKey, "type"]}
                        rules={[{ required: true, message: "" }]}
                      >
                        <Input style={{ width: "10vw" }} placeholder="For" />
                      </Form.Item>
                      <Form.Item
                        label="Prize"
                        {...restField}
                        name={[name, "prize"]}
                        fieldKey={[fieldKey, "prize"]}
                        rules={[{ required: true, message: "" }]}
                      >
                        <Input style={{ width: "10vw" }} placeholder="Price" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      style={{ width: 200 }}
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </Space>
              </Card>
            );
          }}
        </Form.List>
        <div style={{ height: "5vh" }} />

        <Form.Item label="fee" name="fee" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="time" name="time" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="mode" name="mode" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            disabled={!photoUrl || !pdfUrl}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  };
export default CreateFifa;
