import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Space,
  Modal,
  Upload,
  Skeleton,
  Result,
  Typography
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  InboxOutlined
} from "@ant-design/icons";
import { TourApi } from "../../Data/type";
import { editTour } from "../../Data/apis";
import { useEffect, useState } from "react";
import app, { fetchDoc, homeData, state, Tour } from "../../Data/firebase";
import confirm from "antd/lib/modal/confirm";
import { useHistory, useLocation } from "react-router-dom";
import JsonView from "react-json-view";
import moment from "moment";

/*

  tourID: string;
  editDetails: {
    turfID: string | null;
    email: string | null;
    details: {
      discription: string;
      pdf?: [string, string]; // show, url
    };
    main: {
      deadline: string;
      venue: {
        coordinates: { latitude: number; longitude: number };
      };
    };
    prizePool: [string, string][]; // name, price
    info: {
      regestrationFee: number;
      playersPerTeam: number;
      substituteAllowed: number;
      type: string;
      durationPerMatch: number;
      finalsDate: [string, string]; // "yyyy-mm-dd"
    };
  };

*/

const EditTurf: React.FC<{ getJWT: () => Promise<string>; home: homeData }> = ({
  getJWT,
  home
}) => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [show1, setShow1] = useState(false);
  const [tourData, setTourData] = useState<state<Tour>>({
    data: {},
    loading: true
  });
  const history = useHistory();
  const location = useLocation();
  const tourID = location.pathname.slice(8);
  useEffect(() => {
    if (!tourID) setTourData({ data: {}, loading: true });
    else {
      setTourData(x => ({ ...x, loading: true }));
      fetchDoc<Tour>(
        "tournaments/" + tourID,
        undefined,
        d =>
          setTourData(x => {
            x.data.server = d;
            setPdfUrl((d.details.pdf ?? [])[1] ?? "");
            x.loading = false;
            return { ...x };
          }),
        d =>
          setTourData(x => {
            x.data.serverError = d;
            x.loading = false;
            return { ...x };
          }),
        undefined
      );
    }
  }, [tourID]);
  if (!home || home.type === "loading") return <Skeleton />;
  if (home.type === "error")
    return (
      <Result
        status="error"
        title={home.error.code}
        subTitle={home.error.message}
      >
        <Button onClick={window.location.reload}>Refresh</Button>
      </Result>
    );

  if (!tourID)
    return (
      <Space size="large" direction="vertical">
        {Object.entries(home.data.tour).map(([id, obj]) => (
          <Card
            key={id}
            onClick={() => {
              history.push("e/" + id);
            }}
          >
            <JsonView
              src={obj}
              name={id}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              theme="apathy"
            />
          </Card>
        ))}
      </Space>
    );
  const selected = (
    <Typography.Title level={1}>
      {home.data.tour[tourID].name} ({tourID})
    </Typography.Title>
  );
  if (tourData.data.serverError)
    return (
      <>
        {selected}
        <Result
          status="error"
          title={tourData.data.serverError.code}
          subTitle={tourData.data.serverError.message}
        >
          <Button onClick={window.location.reload}>Refresh</Button>
        </Result>
      </>
    );
  if (!tourData.data.server) return <Skeleton />;
  const data = tourData.data.server;
  return (
    <>
      {selected}
      <Form
        name="basic"
        wrapperCol={{ span: 16 }}
        initialValues={{
          remember: true,
          email: data.email,
          discription: data.details.discription,
          "pdf-show": data.details.pdf?.[0],
          deadline: moment(data.main.deadline, "YYYY-MM-DD"),
          prizePool: Object.entries(data.prizePool)
            .sort(([i1], [i2]) => parseInt(i1) - parseInt(i2))
            .map(([, [type, prize]]) => ({ type, prize })),
          fee: data.info.regestrationFee,
          duration: data.info.durationPerMatch,
          type: data.info.type,
          latitude: data.main.venue.coordinates.latitude,
          longitude: data.main.venue.coordinates.longitude,
          playersPerTeam: data.info.playersPerTeam,
          substituteAllowed: data.info.substituteAllowed,
          semiFinal: moment(data.info.dates.semiFinal, "YYYY-MM-DD"),
          final: moment(data.info.dates.final, "YYYY-MM-DD"),
          terms: data.details.terms,
          p1name: data.contact[1].name,
          p1email: data.contact[1].email,
          p1num: data.contact[1].num,
          p2name: data.contact[2].name,
          p2email: data.contact[2].email,
          p2num: data.contact[2].num,
          room1: Object.entries(
            data.rooms[1]
          ).map(([ageRec, { fee, max }]) => ({ ageRec, fee, max })),
          room2: Object.entries(
            data.rooms[2]
          ).map(([ageRec, { fee, max }]) => ({ ageRec, fee, max }))
        }}
        onReset={() => {
          setPdfUrl((data.details.pdf ?? [])[1] ?? "");
        }}
        onFinish={async (a: {
          email: string;
          discription: string;
          deadline: moment.Moment;
          prizePool: { type: string; prize: string }[];
          fee: number;
          duration: string;
          type: string;
          "pdf-show": string;
          longitude: number;
          latitude: number;
          substituteAllowed: number;
          playersPerTeam: number;
          final: moment.Moment;
          semiFinal: moment.Moment;
          terms: string;
          p1name: string;
          p1email: string;
          p1num: number;
          p2name: string;
          p2email: string;
          p2num: number;
          room1: {
            ageRec: string;
            fee: number;
            max: number;
          }[];
          room2: {
            ageRec: string;
            fee: number;
            max: number;
          }[];
        }) => {
          const data: TourApi.edit = JSON.parse(
            JSON.stringify({
              editDetails: {
                turfID: null,
                rooms: {
                  1: (a.room1 ?? []).reduce((obj, { ageRec, fee, max }) => {
                    obj[ageRec] = { fee, max, regestered: [] };
                    return obj;
                  }, {} as any),
                  2: (a.room2 ?? []).reduce((obj, { ageRec, fee, max }) => {
                    obj[ageRec] = { fee, max, regestered: [] };
                    return obj;
                  }, {} as any)
                },
                free: !(
                  Object.values(a.room1).findIndex(({ fee }) => fee !== 1) +
                    1 ||
                  Object.values(a.room2).findIndex(({ fee }) => fee !== 1) + 1
                ),
                details: {
                  discription: a.discription,
                  pdf: [a["pdf-show"], pdfUrl],
                  terms: a.terms
                },
                contact: {
                  1: {
                    email: a.p1email,
                    name: a.p1name,
                    num: a.p1num
                  },
                  2: {
                    email: a.p2email,
                    name: a.p2name,
                    num: a.p2num
                  }
                },
                email: a.email,
                info: {
                  type: a.type,
                  durationPerMatch: a.duration,
                  substituteAllowed: a.substituteAllowed,
                  playersPerTeam: a.playersPerTeam,
                  finalsDate: [a.final, a.semiFinal].map(x =>
                    x.format("YYYY-MM-DD")
                  )
                },
                main: {
                  deadline: a.deadline.format("YYYY-MM-DD"),
                  venue: {
                    coordinates: {
                      latitude: a.latitude,
                      longitude: a.longitude
                    }
                  }
                },
                prizePool: (a.prizePool ?? []).map(({ prize, type }) => [
                  type,
                  prize
                ])
              },
              tourID
            })
          );
          if (!pdfUrl || !a["pdf-show"]) delete data.editDetails.details.pdf;
          confirm({
            title: "Sure you want to do this?",
            async onOk() {
              editTour(await getJWT(), data);
            },
            okText: "Yes"
          });
        }}
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="reset">
            Reset
          </Button>
        </Form.Item>

        <h1>Singles</h1>
        <Form.List name="room1">
          {(fields, { add, remove }) => {
            return (
              <Card>
                <Space direction="vertical">
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space align="baseline" key={key}>
                      <Form.Item
                        label="Age Category ( Eg: Under 15 )"
                        {...restField}
                        name={[name, "ageRec"]}
                        fieldKey={[fieldKey, "ageRec"]}
                        rules={[
                          { required: true, message: "" },
                          {
                            validator(_, value: string) {
                              if (value.indexOf(".") === -1) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('can\'t have a "." in this field')
                              );
                            }
                          }
                        ]}
                      >
                        <Input style={{ width: "10vw" }} placeholder="For" />
                      </Form.Item>
                      <Form.Item
                        label="Category Fee"
                        {...restField}
                        name={[name, "fee"]}
                        fieldKey={[fieldKey, "fee"]}
                        rules={[{ required: true, message: "" }]}
                      >
                        <InputNumber
                          style={{ width: "10vw" }}
                          placeholder="Price"
                        />
                      </Form.Item>
                      <Form.Item
                        label="--Max"
                        {...restField}
                        name={[name, "max"]}
                        fieldKey={[fieldKey, "max"]}
                        rules={[{ required: true, message: "" }]}
                      >
                        <InputNumber
                          style={{ width: "10vw" }}
                          placeholder="Max"
                        />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      style={{ width: 200 }}
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </Space>
              </Card>
            );
          }}
        </Form.List>
        <div style={{ height: 20 }} />
        <h1>Doubles</h1>
        <Form.List name="room2">
          {(fields, { add, remove }) => {
            return (
              <Card>
                <Space direction="vertical">
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space align="baseline" key={key}>
                      <Form.Item
                        label="Age Category ( Eg: Under 15 )"
                        {...restField}
                        name={[name, "ageRec"]}
                        fieldKey={[fieldKey, "ageRec"]}
                        rules={[
                          { required: true, message: "" },
                          {
                            validator(_, value: string) {
                              if (value.indexOf(".") === -1) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('can\'t have a "." in this field')
                              );
                            }
                          }
                        ]}
                      >
                        <Input style={{ width: "10vw" }} placeholder="For" />
                      </Form.Item>
                      <Form.Item
                        label="Category Fee"
                        {...restField}
                        name={[name, "fee"]}
                        fieldKey={[fieldKey, "fee"]}
                        rules={[{ required: true, message: "" }]}
                      >
                        <InputNumber
                          style={{ width: "10vw" }}
                          placeholder="Price"
                        />
                      </Form.Item>
                      <Form.Item
                        label="--Max"
                        {...restField}
                        name={[name, "max"]}
                        fieldKey={[fieldKey, "max"]}
                        rules={[{ required: true, message: "" }]}
                      >
                        <InputNumber
                          style={{ width: "10vw" }}
                          placeholder="Max"
                        />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      style={{ width: 200 }}
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </Space>
              </Card>
            );
          }}
        </Form.List>
        <div style={{ height: 20 }} />

        <Form.Item
          label="Tournament Email Id"
          name="email"
          rules={[
            { required: true, message: "Please input org. email" },
            { type: "email", message: "Enter a valid Email" }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Discription Of Tournament"
          name="discription"
          rules={[{ required: true }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="Terms & Condition's Of the Tournament" name="terms" rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Tournament Organiser Name-1"
          rules={[{ required: true }]}
          name="p1name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tournament Organiser Email-1"
          rules={[{ required: true }]}
          name="p1email"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tournament Organiser Ph Number-1"
          rules={[{ required: true }]}
          name="p1num"
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Tournament Organiser Name-2"
          rules={[{ required: true }]}
          name="p2name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tournament Organiser Email-2"
          rules={[{ required: true }]}
          name="p2email"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tournament Organiser Ph Number-2"
          rules={[{ required: true }]}
          name="p2num"
        >
          <InputNumber />
        </Form.Item>
        <Space>
          {pdfUrl ? (
            <Button onClick={() => setPdfUrl("")} danger>
              Remove Pdf
            </Button>
          ) : (
            <Button onClick={() => setShow1(true)}>Uplode Terms & Conditions PDF</Button>
          )}
          <Input readOnly value={pdfUrl} style={{ width: 700 }} />
          <Modal
            destroyOnClose
            visible={show1}
            onCancel={() => setShow1(false)}
            footer={null}
          >
            <Upload.Dragger
              maxCount={1}
              name="file"
              customRequest={async file => {
                const { floor, random } = Math;
                let result = "";
                for (let i = 0; i < 15; i++)
                  result += "qwertyuiopasdfghjklzxcvbnm7896341520ZXCVBNMASDFGHJKLPOIUYTREWQ".charAt(
                    floor(random() * 62)
                  );
                const x_1 = await app
                  .storage()
                  .ref("tour/" + result)
                  .put(file.file as Blob);
                setPdfUrl(await x_1.ref.getDownloadURL());
                setShow1(false);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Modal>
        </Space>
        <div style={{ height: 20 }} />
        {pdfUrl && (
          <Form.Item
            rules={[{ required: true }]}
            label="Write Download Terms & Conditions"
            name="pdf-show"
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          label="Tournament Booking Deadline"
          name="deadline"
          rules={[{ required: true }]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          name="latitude"
          label="(Google Map -Latitude( Get from Google Maps))"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="longitude"
          label="(Google Map -Longitude( Get from Google Maps))"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Semi-Final Date"
          name="semiFinal"
          rules={[{ required: true }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item label="Date of final" name="final" rules={[{ required: true }]}>
          <DatePicker />
        </Form.Item>
        <Form.List name="prizePool">
          {(fields, { add, remove }) => {
            return (
              <Card>
                <Space direction="vertical">
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space align="baseline" key={key}>
                      <Form.Item
                        label="Category( Eg: Under15 )"
                        {...restField}
                        name={[name, "type"]}
                        fieldKey={[fieldKey, "type"]}
                        rules={[{ required: true, message: "" }]}
                      >
                        <Input style={{ width: "10vw" }} placeholder="For" />
                      </Form.Item>
                      <Form.Item
                        label="Prize"
                        {...restField}
                        name={[name, "prize"]}
                        fieldKey={[fieldKey, "prize"]}
                        rules={[{ required: true, message: "" }]}
                      >
                        <Input style={{ width: "10vw" }} placeholder="Price" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      style={{ width: 200 }}
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </Space>
              </Card>
            );
          }}
        </Form.List>
        <div style={{ height: "5vh" }} />
        {/* <Form.Item label="fee" name="fee" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item> */}
        <Form.Item
          label="Duration per Match(In Minutes)"
          name="duration"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Tournament type" name="type" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Players Per Team"
          name="playersPerTeam"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Number Of Substitute Allowed"
          name="substituteAllowed"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button disabled={!pdfUrl} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default EditTurf;
