import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Space,
  Modal,
  Upload,
  Skeleton,
  Result,
  Typography,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { TourApi } from "../../Data/type";
import { superEditTour } from "../../Data/apis";
import { useEffect, useState } from "react";
import app, { homeData } from "../../Data/firebase";
import confirm from "antd/lib/modal/confirm";
import { useHistory, useLocation } from "react-router-dom";
import JsonView from "react-json-view";
import moment from "moment";

/*

  editDetails: {
    title: string;
    ageRec: string;
    photo: string;
    venue: { address: string[] };
    timeLine: [string, string]; // start-end
    roomInc: number;
  };
  tourID: string;

*/

const SuperEditTurf: React.FC<{
  getJWT: () => Promise<string>;
  home: homeData;
}> = ({ getJWT, home }) => {
  const [photoUrl, setPhotoUrl] = useState("");
  const [show, setShow] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const tourID = location.pathname.slice(8);
  useEffect(() => {
    if (home?.type !== "valid" || !tourID) return;
    setPhotoUrl(home.data.tour[tourID].photo);
  }, [tourID, home]);
  if (!home || home.type === "loading") return <Skeleton />;
  if (home.type === "error")
    return (
      <Result
        status="error"
        title={home.error.code}
        subTitle={home.error.message}
      >
        <Button onClick={window.location.reload}>Refresh</Button>
      </Result>
    );
  if (!tourID)
    return (
      <Space size="large" direction="vertical">
        {Object.entries(home.data.tour).map(([id, obj]) => (
          <Card
            key={id}
            onClick={() => {
              history.push("s/" + id);
            }}
          >
            <JsonView
              src={obj}
              name={id}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              theme="apathy"
            />
          </Card>
        ))}
      </Space>
    );
  const data = home.data.tour[tourID];
  return (
    <>
      <Typography.Title level={1}>
        {home.data.tour[tourID].name} ({tourID})
      </Typography.Title>
      <Form
        name="basic"
        wrapperCol={{ span: 16 }}
        initialValues={{
          remember: true,
          title: data.name,
          "address-l1": data.venue[0],
          "address-l2": data.venue[1],
          "address-l3": data.venue[2] ?? "",
          total: data.room.total,
          ageRec: data.ageRec,

          timeline: [
            moment(data.finalsDate[0], "YYYY-MM-DD"),
            moment(data.finalsDate[1], "YYYY-MM-DD"),
          ],
        }}
        onFinish={async (a: {
          title: string;
          "address-l1": string;
          "address-l2": string;
          "address-l3": string;
          total: number;
          timeline: [moment.Moment, moment.Moment];
          ageRec: number;
        }) => {
          const data: TourApi.superEdit = JSON.parse(
            JSON.stringify({
              tourID,
              editDetails: {
                timeLine: a.timeline.map((x) => x.format("YYYY-MM-DD")),
                photo: photoUrl,
                roomInc: a.total,
                title: a.title,
                venue: {
                  address: [a["address-l1"], a["address-l2"], a["address-l3"]],
                },
                ageRec: a.ageRec,
              },
            })
          );
          if (!a["address-l3"]) data.editDetails.venue.address.pop();
          confirm({
            title: "Sure you want to do this?",
            async onOk() {
              superEditTour(await getJWT(), data);
            },
            okText: "Yes",
          });
        }}
        onReset={() => {
          setPhotoUrl(data.photo);
        }}
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="reset">
            Reset
          </Button>
        </Form.Item>
        <Space>
          {photoUrl ? (
            <Button onClick={() => setPhotoUrl("")} danger>
              Remove Image
            </Button>
          ) : (
            <Button onClick={() => setShow(true)}>Uplode Image</Button>
          )}
          <Input readOnly value={photoUrl} style={{ width: 700 }} />
          <Modal
            destroyOnClose
            visible={show}
            onCancel={() => setShow(false)}
            footer={null}
          >
            <Upload.Dragger
              maxCount={1}
              name="file"
              customRequest={async (file) => {
                const { floor, random } = Math;
                let result = "";
                for (let i = 0; i < 15; i++)
                  result +=
                    "qwertyuiopasdfghjklzxcvbnm7896341520ZXCVBNMASDFGHJKLPOIUYTREWQ".charAt(
                      floor(random() * 62)
                    );
                const x_1 = await app
                  .storage()
                  .ref("tour/" + result)
                  .put(file.file as Blob);
                setPhotoUrl(await x_1.ref.getDownloadURL());
                setShow(false);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Modal>
        </Space>
        <div style={{ height: 20 }} />
        <Form.Item
          label="Name of Tournament"
          name="title"
          rules={[{ required: true, message: "Please input Title" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Age Group ( Eg: U18-U20 )" name="ageRec" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Tournament Venue/Address Line 1"
          name="address-l1"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tournament Venue/Address Line 2"
          name="address-l2"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Tournament Venue/Address Line 3" name="address-l3">
          <Input />
        </Form.Item>
        <Form.Item label="Total Number Of Teams" name="total" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Tournament Timeine ( Eg : 30th-31st )"
          name="timeline"
          rules={[{ required: true }]}
        >
          <DatePicker.RangePicker />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default SuperEditTurf;
