import { message } from "antd";
import axios from "axios";
import { FbReturn, FifaApi, TourApi } from "./type";
import { baseUrl } from "../appConfig.json";

const adminBaseUrl = baseUrl + "/adminsideApi";

const Urls = {
  tour: {
    create: adminBaseUrl + "/tour/c",
    edit: adminBaseUrl + "/tour/e",
    superEdit: adminBaseUrl + "/tour/s",
  },
  fifa: {
    create: adminBaseUrl + "/fifa/c",
    edit: adminBaseUrl + "/fifa/e",
    superEdit: adminBaseUrl + "/fifa/s",
  },
};

export const handleError = (error: any): FbReturn => {
  try {
    if (typeof error.code === "string" && typeof error.message === "string")
      return error;
  } catch {}
  console.error("🤔🤔 an error occured \n", error);
  return { code: "local error", message: "unknown error occured" + error };
};

const postCall = async (url: string, jwt: string, data: any) => {
  try {
    const load = message.loading("sending stuff", 0);
    const responce = await axios({
      url,
      headers: { authorization: "Bearer " + jwt },
      data,
      method: "POST",
    });
    load();
    if (responce.status === 200) {
      message.success("Done!");
      return { type: "valid" as "valid", data: responce.data };
    }
    const err = handleError(responce.data);
    message.error(err.message);
    return {
      type: "server-error" as "server-error",
      error: err,
    };
  } catch (error) {
    const err = handleError(error);
    message.error(err.message);
    return { type: "error" as "error", error: err };
  }
};

export const createTour = async (
  jwt: string,
  details: TourApi.create
): Promise<FbReturn | string> => {
  const res = await postCall(Urls.tour.create, jwt, details);
  return res.type === "valid" ? res.data.tourID : res.error;
};

export const editTour = async (
  jwt: string,
  details: TourApi.edit
): Promise<FbReturn | null> => {
  const res = await postCall(Urls.tour.edit, jwt, details);
  return res.type === "valid" ? null : res.error;
};

export const superEditTour = async (
  jwt: string,
  details: TourApi.superEdit
): Promise<FbReturn | null> => {
  const res = await postCall(Urls.tour.superEdit, jwt, details);
  return res.type === "valid" ? null : res.error;
};

export const createFifa = async (
  jwt: string,
  details: FifaApi.create
): Promise<FbReturn | string> => {
  const res = await postCall(Urls.fifa.create, jwt, details);
  return res.type === "valid" ? res.data.fifaID : res.error;
};

export const editFifa = async (
  jwt: string,
  details: FifaApi.edit
): Promise<FbReturn | null> => {
  const res = await postCall(Urls.fifa.edit, jwt, details);
  return res.type === "valid" ? null : res.error;
};

export const superEditFifa = async (
  jwt: string,
  details: FifaApi.superEdit
): Promise<FbReturn | null> => {
  const res = await postCall(Urls.fifa.superEdit, jwt, details);
  return res.type === "valid" ? null : res.error;
};
