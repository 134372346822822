import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import { handleError } from "./apis";
import { firebaseConfig } from "../appConfig.json";

const app = firebase.initializeApp(firebaseConfig);

app.firestore().enablePersistence();
export interface state<T> {
  loading: boolean;
  data: {
    serverError?: { code: string; message: string };
    cacheError?: { code: string; message: string };
    cache?: T;
    server?: T;
  };
}
export interface Fifa {
  poster: string;
  email?: string;
  details: {
    title: string;
    discription: string;
    pdf?: [string, string]; // show, url
    terms: string;
  };
  contact: {
    1: { email: string; num: number; name: string };
    2: { email: string; num: number; name: string };
  };
  main: {
    gameDate: string;
    deadLine: string;
    venue: {
      address: Array<string>;
      coordinates: { latitude: number; longitude: number };
    };
    rooms: { total: number; taken: Array<string> };
  };
  prizePool: { [i: number]: [string, string] }; // name, price
  info: {
    regestrationFee: number;
    time: string;
    mode: string;
  };
}
export interface Tour {
  orderID?: string;
  email?: string;
  turfID?: string;
  rooms: {
    1: {
      [ageRec: string]: {
        max: number; // 10
        fee: number;
        regestered: string[]; // 10
      };
    };
    2: {
      [ageRec: string]: {
        max: number; // 15
        fee: number;
        regestered: string[]; // 5
      };
    };
    total: number; // 20
    totalReg: string[];
  };
  details: {
    poster: string;
    title: string;
    discription: string;
    pdf?: [string, string]; // show, url
    terms: string;
  };
  contact: {
    1: { email: string; num: number; name: string };
    2: { email: string; num: number; name: string };
  };
  main: {
    rooms: { total: number; registered: Array<string> };
    venue: {
      address: Array<string>;
      coordinates: { latitude: number; longitude: number };
    };
    ageRec: string;
    deadline: string;
    timeline: [string, string]; // start-end
  };
  prizePool: { [i: number]: [string, string] };
  info: {
    regestrationFee: number;
    playersPerTeam: number;
    substituteAllowed: number;
    type: string;
    durationPerMatch: number;
    dates: {
      semiFinal: string; // yyyy-dd-mm
      final: string; // yyyy-dd-mm
    };
  };
}
export type homeData =
  | { type: "loading" }
  | {
      type: "valid";
      data: {
        city: {
          [name: string]: {
            turf: string[];
            tour: string[];
            fifa: string[];
          };
        };
        turf: {
          [turfID: string]: {
            points: number;
            name: string;
            area: Array<string>;
            photo: string;
            rate: { total: number; users: number };
            offer?: number;
          };
        };
        tour: {
          [tourID: string]: {
            photo: string;
            name: string;
            venue: string[];
            finalsDate: [string, string]; // "yyyy-mm-dd"
            ageRec: string;
            room: { total: number; taken: Array<string> };
          };
        };
        fifa: {
          [tourID: string]: {
            photo: string;
            name: string;
            venue: string[];
            date: string; // "yyyy-mm-dd"
            room: { total: number; taken: Array<string> };
          };
        };
      };
    }
  | { type: "error"; error: { code: string; message: string } }
  | undefined;

export const subscribeHome = (onStateChange: (data: homeData) => void) => {
  onStateChange({ type: "loading" });
  return app
    .firestore()
    .doc("config/home")
    .onSnapshot(
      (snapshot) => {
        onStateChange({ type: "valid", data: snapshot.data() as any });
      },
      (error) => onStateChange({ type: "error", error }),
      () => onStateChange(undefined)
    );
};

export const fetchDoc = <T>(
  path: string,
  onCache: undefined | ((x: T) => void),
  onServer: undefined | ((x: T) => void),
  onServerError: undefined | ((x: { code: string; message: string }) => void),
  onCacheError: undefined | ((x: { code: string; message: string }) => void)
) => {
  const ref = app.firestore().doc(path);
  onCache &&
    ref
      .get({ source: "cache" })
      .then((snapshot) => {
        if (snapshot.exists) onCache(snapshot.data() as any);
      })
      .catch((err) => onCacheError && onCacheError(handleError(err)));
  onServer &&
    ref
      .get({ source: "server" })
      .then((snapshot) => {
        onServer(snapshot.data() as any);
      })
      .catch((err) => onServerError && onServerError(handleError(err)));
};

export const fetchCareer = async () => {
  const array:{id : string, data : {fullName : string;
    contactNumber : string;
    emailID : string;
    cityOfResidence : string;
    department : string;
    cvUrl : string;
    portfolioURL : string;}}[] = []
  await app.firestore().collection("careers").get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
        array.push({id : doc.id, data : doc.data() as any});
        // console.log(doc.id, " => ", doc.data());
    });
})
  return array;
}

export const deleteCareerDocument = async(id : string) => {
  return app.firestore().collection("careers").doc(id).delete().then(() => {
    return null;
}).catch((error) => {
    return handleError(error);
});

}
export default app;
 