import { useHistory, useLocation } from "react-router-dom";
import { Layout, Menu, PageHeader } from "antd";
import {
  LogoutOutlined,
  UserOutlined,
  FormOutlined,
  AppstoreAddOutlined,
  FireOutlined,
  HomeOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import app from "../Data/firebase";

const { Content, Footer, Sider } = Layout;

const DashBord: React.FC<{ user: firebase.default.User | null }> = ({
  children,
  user,
}) => {
  const logout = () => app.auth().signOut();
  const path = useLocation().pathname;
  const [mainSelect, subSelect] = path.split("/").slice(1) as
    | ["tour" | "fifa", "c" | "e" | "s"];
  const history = useHistory();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible>
        <div
          style={{
            height: 32,
            margin: 16,
            background: "rgba(255, 255, 255, 0.3)",
          }}
        />
        <Menu mode="inline" selectedKeys={[`${mainSelect}-${subSelect}`]}>
          <Menu.Item
            icon={<HomeOutlined />}
            onClick={() => history.push("/")}
            key="home"
          >
            Home
          </Menu.Item>
          <Menu.Item onClick={() => history.push("/partners")} key="partners">
            Partners
          </Menu.Item>
          <Menu.Item onClick={() => history.push("/careers")} key="careers">
            Careers
          </Menu.Item>
          <Menu.SubMenu key="tour" title="Tournament">
            <Menu.Item
              onClick={() => history.push("/tour/c")}
              icon={<AppstoreAddOutlined />}
              key="tour-c"
            >
              create
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push("/tour/e")}
              icon={<FormOutlined />}
              key="tour-e"
            >
              edit
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push("/tour/s")}
              icon={<FireOutlined />}
              key="tour-s"
            >
              super edit
            </Menu.Item>
            {/* <Menu.Item
              onClick={() => history.push("/tour/r")}
              icon={<ReconciliationOutlined />}
              key="tour-r"
            >
              records
            </Menu.Item> */}
          </Menu.SubMenu>
          {/* <Menu.SubMenu key="fifa" title="Fifa">
            <Menu.Item
              onClick={() => history.push("/fifa/c")}
              icon={<AppstoreAddOutlined />}
              key="fifa-c"
            >
              create
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push("/fifa/e")}
              icon={<FormOutlined />}
              key="fifa-e"
            >
              edit
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push("/fifa/s")}
              icon={<FireOutlined />}
              key="fifa-s"
            >
              super edit
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push("/fifa/r")}
              icon={<ReconciliationOutlined />}
              key="fifa-r"
            >
              records
            </Menu.Item>
          </Menu.SubMenu> */}
          <Menu.Item
            icon={<LogoutOutlined />}
            onClick={() => {
              confirm({
                title: "Do you want to LogOut?",
                icon: <UserOutlined />,
                okText: "Yes",
                maskClosable: true,
                onOk: logout,
              });
            }}
            key="logout"
          >
            Log-Out
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <PageHeader
          title={"Admins's Account -" + (user?.displayName ?? "")}
          onBack={() => history.goBack()}
        />
        <Content style={{ margin: "0 16px" }}>
          <br />
          {children}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Huddle and Score Admin Site
        </Footer>
      </Layout>
    </Layout>
  );
};

export default DashBord;
