import { useEffect } from "react";
import { useState } from "react";
import { fetchDoc, state } from "../Data/firebase";
import JsonView from "react-json-view";
import { Col, Row, Skeleton } from "antd";

const FeedBack: React.FC = () => {
  const [feedBack, setFeedBack] = useState<state<any>>({
    loading: true,
    data: {},
  });
  useEffect(() => {
    setFeedBack((x) => ({ ...x, loading: true }));
    fetchDoc(
      "config/feedBack",
      (d) =>
        setFeedBack((x) => {
          x.data.cache = d;
          return { ...x };
        }),
      (d) =>
        setFeedBack((x) => {
          x.data.server = d;
          x.loading = false;
          return { ...x };
        }),
      (d) =>
        setFeedBack((x) => {
          x.data.serverError = d;
          x.loading = false;
          return { ...x };
        }),
      undefined
    );
  }, []);
  return (
    <Row justify="space-between" align="top">
      <Col span={4}>
        <JsonView
          style={{ width: "30vw" }}
          src={feedBack.data.cache}
          name="cache"
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={false}
          theme="apathy"
        />
      </Col>
      <Col offset={4}>
        {feedBack.loading ? (
          <Skeleton />
        ) : (
          <JsonView
            style={{ width: "30vw" }}
            src={feedBack.data.server}
            name="server"
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            theme="apathy"
          />
        )}
      </Col>
    </Row>
  );
};
export default FeedBack;
