import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Result,
  Skeleton,
  Space,
  Typography,
  Upload,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { FifaApi } from "../../Data/type";
import { editFifa } from "../../Data/apis";
import { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import app, { fetchDoc, Fifa, homeData, state } from "../../Data/firebase";
import JsonView from "react-json-view";
import { useEffect } from "react";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import confirm from "antd/lib/modal/confirm";
/*

  fifaID: string;
  editDetails: {
    email: string | null;
    details: {
      discription: string;
      pdf?: [string, string]; // show, url
    };
    main: { gameDate: string; deadLine: string };
    prizePool: [string, string][]; // name, price
    info: { regestrationFee: number; time: string; mode: string };
  };

*/

const EditFifa: React.FC<{ getJWT: () => Promise<string>; home: homeData }> = ({
  getJWT,
  home,
}) => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [show1, setShow1] = useState(false);
  const [fifaData, setFifaData] = useState<state<Fifa>>({
    data: {},
    loading: true,
  });
  const history = useHistory();
  const location = useLocation();
  const fifaID = location.pathname.slice(8);
  useEffect(() => {
    if (!fifaID) setFifaData({ data: {}, loading: true });
    else {
      setFifaData((x) => ({ ...x, loading: true }));
      fetchDoc<Fifa>(
        "fifas/" + fifaID,
        undefined,
        (d) =>
          setFifaData((x) => {
            x.data.server = d;
            setPdfUrl((d.details.pdf ?? [])[1] ?? "");
            x.loading = false;
            return { ...x };
          }),
        (d) =>
          setFifaData((x) => {
            x.data.serverError = d;
            x.loading = false;
            return { ...x };
          }),
        undefined
      );
    }
  }, [fifaID]);
  if (!home || home.type === "loading") return <Skeleton />;
  if (home.type === "error")
    return (
      <Result
        status="error"
        title={home.error.code}
        subTitle={home.error.message}
      >
        <Button onClick={window.location.reload}>Refresh</Button>
      </Result>
    );

  if (!fifaID)
    return (
      <Space size="large" direction="vertical">
        {Object.entries(home.data.fifa).map(([id, obj]) => (
          <Card
            key={id}
            onClick={() => {
              history.push("e/" + id);
            }}
          >
            <JsonView
              src={obj}
              name={id}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              theme="apathy"
            />
          </Card>
        ))}
      </Space>
    );
  const selected = (
    <Typography.Title level={1}>
      {home.data.fifa[fifaID].name} ({fifaID})
    </Typography.Title>
  );
  if (fifaData.data.serverError)
    return (
      <>
        {selected}
        <Result
          status="error"
          title={fifaData.data.serverError.code}
          subTitle={fifaData.data.serverError.message}
        >
          <Button onClick={window.location.reload}>Refresh</Button>
        </Result>
      </>
    );
  if (!fifaData.data.server) return <Skeleton />;
  const data = fifaData.data.server;
  return (
    <>
      {selected}
      <Form
        name="basic"
        wrapperCol={{ span: 16 }}
        initialValues={{
          remember: true,
          email: data.email,
          discription: data.details.discription,
          "pdf-show": data.details.pdf?.[0],
          deadLine: moment(data.main.deadLine, "YYYY-MM-DD"),
          prizePool: Object.entries(data.prizePool)
            .sort(([i1], [i2]) => parseInt(i1) - parseInt(i2))
            .map(([, [type, prize]]) => ({ type, prize })),
          fee: data.info.regestrationFee,
          time: data.info.time,
          mode: data.info.mode,
          latitude: data.main.venue.coordinates.latitude,
          longitude: data.main.venue.coordinates.longitude,
          terms: data.details.terms,
          p1name: data.contact[1].name,
          p1email: data.contact[1].email,
          p1num: data.contact[1].num,
          p2name: data.contact[2].name,
          p2email: data.contact[2].email,
          p2num: data.contact[2].num,
        }}
        onReset={() => {
          setPdfUrl((data.details.pdf ?? [])[1] ?? "");
        }}
        onFinish={async (a: {
          email: string;
          discription: string;
          deadLine: moment.Moment;
          prizePool: { type: string; prize: string }[];
          fee: number;
          time: string;
          mode: string;
          "pdf-show": string;
          longitude: number;
          latitude: number;
          terms: string;
          p1name: string;
          p1email: string;
          p1num: number;
          p2name: string;
          p2email: string;
          p2num: number;
        }) => {
          const data: FifaApi.edit = JSON.parse(
            JSON.stringify({
              editDetails: {
                details: {
                  discription: a.discription,
                  pdf: [a["pdf-show"], pdfUrl],
                  terms: a.terms,
                },
                contact: {
                  1: {
                    email: a.p1email,
                    name: a.p1name,
                    num: a.p1num,
                  },
                  2: {
                    email: a.p2email,
                    name: a.p2name,
                    num: a.p2num,
                  },
                },
                free : a.fee===1,
                email: a.email,
                info: { mode: a.mode, regestrationFee: a.fee, time: a.time },
                main: {
                  deadLine: a.deadLine.format("YYYY-MM-DD"),
                },
                venue: {
                  coordinates: { latitude: a.latitude, longitude: a.longitude },
                },
                prizePool: (a.prizePool ?? []).map(({ prize, type }) => [
                  type,
                  prize,
                ]),
              },
              fifaID,
            })
          );
          if (!pdfUrl || !a["pdf-show"]) delete data.editDetails.details.pdf;
          confirm({
            title: "Sure you want to do this?",
            async onOk() {
              editFifa(await getJWT(), data);
            },
            okText: "Yes",
          });
        }}
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="reset">
            Reset
          </Button>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please input org. email" },
            { type: "email", message: "Enter a valid Email" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Discription"
          name="discription"
          rules={[{ required: true }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="terms" name="terms" rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="person1-name"
          rules={[{ required: true }]}
          name="p1name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="person1-email"
          rules={[{ required: true }]}
          name="p1email"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="person1-num"
          rules={[{ required: true }]}
          name="p1num"
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="person2-name"
          rules={[{ required: true }]}
          name="p2name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="person2-email"
          rules={[{ required: true }]}
          name="p2email"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="person2-num"
          rules={[{ required: true }]}
          name="p2num"
        >
          <InputNumber />
        </Form.Item>
        <Space>
          {pdfUrl ? (
            <Button onClick={() => setPdfUrl("")} danger>
              Remove Pdf
            </Button>
          ) : (
            <Button onClick={() => setShow1(true)}>Uplode PDF</Button>
          )}
          <Input readOnly value={pdfUrl} style={{ width: 700 }} />
          <Modal
            destroyOnClose
            visible={show1}
            onCancel={() => setShow1(false)}
            footer={null}
          >
            <Upload.Dragger
              maxCount={1}
              name="file"
              customRequest={async (file) => {
                const { floor, random } = Math;
                let result = "";
                for (let i = 0; i < 15; i++)
                  result +=
                    "qwertyuiopasdfghjklzxcvbnm7896341520ZXCVBNMASDFGHJKLPOIUYTREWQ".charAt(
                      floor(random() * 62)
                    );
                const x_1 = await app
                  .storage()
                  .ref("fifa/" + result)
                  .put(file.file as Blob);
                setPdfUrl(await x_1.ref.getDownloadURL());
                setShow1(false);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Modal>
        </Space>
        <div style={{ height: 20 }} />

        {pdfUrl && (
          <Form.Item
            rules={[{ required: true }]}
            label="pdf-show"
            name="pdf-show"
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item
          label="deadLine"
          name="deadLine"
          rules={[{ required: true }]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>

        <Form.Item
          name="latitude"
          label="latitude"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="longitude"
          label="longitude"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>

        <Form.List name="prizePool">
          {(fields, { add, remove }) => {
            return (
              <Card>
                <Space direction="vertical">
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space align="baseline" key={key}>
                      <Form.Item
                        label="For"
                        {...restField}
                        name={[name, "type"]}
                        fieldKey={[fieldKey, "type"]}
                        rules={[{ required: true, message: "" }]}
                      >
                        <Input style={{ width: "10vw" }} placeholder="For" />
                      </Form.Item>
                      <Form.Item
                        label="Prize"
                        {...restField}
                        name={[name, "prize"]}
                        fieldKey={[fieldKey, "prize"]}
                        rules={[{ required: true, message: "" }]}
                      >
                        <Input style={{ width: "10vw" }} placeholder="Price" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      style={{ width: 200 }}
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </Space>
              </Card>
            );
          }}
        </Form.List>
        <div style={{ height: "5vh" }} />

        <Form.Item label="fee" name="fee" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="time" name="time" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="mode" name="mode" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button disabled={!pdfUrl} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default EditFifa;
