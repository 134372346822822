import {
  Button,
  Card,
  Divider,
  Result,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import app, { fetchDoc, homeData, state } from "../../Data/firebase";
import { useHistory, useLocation } from "react-router-dom";
import JsonView from "react-json-view";
import { useEffect, useState } from "react";
import { handleError } from "../../Data/apis";
import { baseUrl } from "../../appConfig.json";

const TourRecords: React.FC<{ home: homeData }> = ({ home }) => {
  const history = useHistory();
  const location = useLocation();
  const tourID = location.pathname.slice(8);
  const [records, setRecords] = useState<state<any>>({
    loading: true,
    data: {},
  });
  const [token, setToken] = useState<string>();
  useEffect(() => {
    app
      .database()
      .ref(`token/tour/${tourID}`)
      .get()
      .then((s) => setToken(s.val()), handleError);
  }, [tourID]);
  useEffect(() => {
    if (tourID) {
      setRecords((x) => ({ ...x, loading: true }));
      fetchDoc(
        `tournaments/${tourID}/utils/team`,
        undefined,
        (d) =>
          setRecords((x) => {
            x.data.server = d ?? {};
            x.loading = false;
            return { ...x };
          }),
        (d) =>
          setRecords((x) => {
            x.data.serverError = d;
            x.loading = false;
            return { ...x };
          }),
        undefined
      );
    }
    return () => setRecords({ loading: true, data: {} });
  }, [tourID]);
  if (!home || home.type === "loading") return <Skeleton />;
  if (home.type === "error")
    return (
      <Result
        status="error"
        title={home.error.code}
        subTitle={home.error.message}
      >
        <Button onClick={window.location.reload}>Refresh</Button>
      </Result>
    );
  if (!tourID)
    return (
      <Space size="large" direction="vertical">
        {Object.entries(home.data.tour).map(([id, obj]) => (
          <Card
            key={id}
            onClick={() => {
              history.push("r/" + id);
            }}
          >
            <JsonView
              src={obj}
              name={id}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              theme="apathy"
            />
          </Card>
        ))}
      </Space>
    );
  if (records.loading) return <Skeleton />;
  if (records.data.serverError)
    return (
      <Result
        status="error"
        title={records.data.serverError.code}
        subTitle={records.data.serverError.message}
      >
        <Button onClick={window.location.reload}>Refresh</Button>
      </Result>
    );
  return (
    <>
      <Typography.Title level={1}>
        {home.data.tour[tourID].name} ({tourID})
      </Typography.Title>
      <Typography.Paragraph
        copyable={{
          text: JSON.stringify(
            Object.entries(records.data.server).map(([srNum, obj]) => ({
              ...(obj as any),
              srNum,
            }))
          ),
        }}
      >
        Copy Records
      </Typography.Paragraph>
      <JsonView
        src={records.data.server}
        name={false}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        theme="apathy"
      />
      <Divider />
      <Spin spinning={!token}>
        <Typography.Title>Using ImportJson in google sheets</Typography.Title>
        <Typography.Paragraph
          copyable={{
            text: `=ImportJson("${baseUrl}/excelApis/tour/${tourID}/${token}")`,
          }}
        >
          {baseUrl}/excelApis/tour/{tourID}/{token}
        </Typography.Paragraph>
      </Spin>
    </>
  );
};
export default TourRecords;
