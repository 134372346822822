import { useEffect, useState } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";

import Cookies from "js-cookie";
import "antd/dist/antd.dark.css";

import app, { homeData, subscribeHome } from "./Data/firebase";
import Dashbord from "./Pages/Dashbord";
import CreateTour from "./Pages/Template/createTour";
import CreateFifa from "./Pages/Template/createFifa";
import EditTour from "./Pages/Template/editTour";
import EditFifa from "./Pages/Template/editFifa";
import SuperEditTour from "./Pages/Template/superEditTour";
import SuperEditFifa from "./Pages/Template/superEditFifa";
import Login from "./Pages/Login";
import Careers from "./Pages/Careers";
import FeedBack from "./Pages/Feedback";
import PartnerWithUs from "./Pages/PartnerWithUs";
import { Button, Result } from "antd";
import FifaRecords from "./Pages/Template/fifaRecords";
import TourRecords from "./Pages/Template/tourRecords";

function App() {
  const userSpefificID = "K27BmRLXdMT8G7QJ253NdOxUeTC2";
  const [user, setUser] = useState<firebase.default.User | null>(null);
  // const [loading, setLoading] = useState("")
  // const [uid, setUid] = useState<string | undefined>(Cookies.get("uid"));
  const [errAlert, setErrAlert] = useState<
    { code: "no-clearence" | "not-authorized"; message: string } | undefined
  >();
  const [homeData, setHomeData] = useState<homeData>();
  useEffect(() => {
    const unsub = app.auth().onAuthStateChanged((fbUser) => {
      setUser(fbUser);
      if (fbUser) {
        const ref = app.database().ref(`admin/${fbUser.uid}`);
        ref.on("value", (snapshot) => {
          const val = snapshot.val();
          const exist = snapshot.exists();
          if (exist && val === "🔥🔥🔥🔥") {
            Cookies.set("uid", fbUser.uid);
            // setUid(fbUser.uid);
            setErrAlert(undefined);
          } else {
            setErrAlert(
              val !== "🔥🔥🔥🔥" && exist
                ? {
                  code: "no-clearence",
                  message: "Contect someone in IT Department \n" + val,
                }
                : {
                  code: "not-authorized",
                  message: "You are not Autorized to be on this site",
                }
            );
            ref.off("value");
            app.auth().signOut();
          }
        });
      } else {
        // setUid(undefined);
        Cookies.remove("uid");
      }
    });
    return unsub;
  }, []);
  useEffect(() => {
    let unsub = () => { };
    if (user) unsub = subscribeHome(setHomeData);
    return unsub;
  }, [user]);
  const uid = user?.uid;
  if (!uid) return <Login err={errAlert} />;
  return (
    <Switch>
      <Route path="/">
        {uid === userSpefificID ? (
          <Dashbord user={user}>
            <Switch>
              <Route exact path="/tour/c">
                <CreateTour
                  home={homeData}
                  getJWT={() => user?.getIdToken() ?? Promise.resolve("")}
                />
              </Route>
              <Route exact path={["/tour/e/:id", "/tour/e"]}>
                <EditTour
                  home={homeData}
                  getJWT={() => user?.getIdToken() ?? Promise.resolve("")}
                />
              </Route>
              <Route exact path={["/tour/s/:id", "/tour/s"]}>
                <SuperEditTour
                  home={homeData}
                  getJWT={() => user?.getIdToken() ?? Promise.resolve("")}
                />
              </Route>
              <Route exact path={["/tour/r/:id", "/tour/r"]}>
                <TourRecords home={homeData} />
              </Route>
              <Route exact path="/fifa/c">
                <CreateFifa
                  home={homeData}
                  getJWT={() => user?.getIdToken() ?? Promise.resolve("")}
                />
              </Route>
              <Route exact path={["/fifa/e/:id", "/fifa/e"]}>
                <EditFifa
                  home={homeData}
                  getJWT={() => user?.getIdToken() ?? Promise.resolve("")}
                />
              </Route>
              <Route exact path={["/fifa/s/:id", "/fifa/s"]}>
                <SuperEditFifa
                  home={homeData}
                  getJWT={() => user?.getIdToken() ?? Promise.resolve("")}
                />
              </Route>
              <Route exact path={["/fifa/r/:id", "/fifa/r"]}>
                <FifaRecords home={homeData} />
              </Route>
              <Route exact path="/careers">
                <Careers />
              </Route>
              <Route exact path="/">
                <FeedBack />
              </Route>
              <Route exact path="/partners">
                <PartnerWithUs />
              </Route>

              <Route path="*">
                <Result
                  status="404"
                  title="No Such Page"
                  subTitle={
                    <Link to="/">
                      <Button>Home</Button>
                    </Link>
                  }
                />
              </Route>
            </Switch>
          </Dashbord>
        ) : (
          // <Redirect to="/login" />
          <Dashbord user={user}>
            <Careers />
          </Dashbord>
        )}
      </Route>
    </Switch>
  );
}

export default App;
