import { useEffect } from "react";
import { useState } from "react";
import { fetchDoc, state } from "../Data/firebase";
import JsonView from "react-json-view";
import { DatePicker, Skeleton, Space, Typography } from "antd";
import { Moment } from "moment";

const PartnerWithUs: React.FC = () => {
  const [feedBack, setFeedBack] = useState<state<any>>({
    loading: true,
    data: {},
  });
  const [date, setDate] = useState<Moment | null>(null);
  useEffect(() => {
    setFeedBack((x) => ({ ...x, loading: true }));
    fetchDoc(
      "config/partnerReq",
      undefined,
      (d) =>
        setFeedBack((x) => {
          x.data.server = d;
          x.loading = false;
          return { ...x };
        }),
      (d) =>
        setFeedBack((x) => {
          x.data.serverError = d;
          x.loading = false;
          return { ...x };
        }),
      undefined
    );
  }, []);
  return feedBack.loading ? (
    <Skeleton />
  ) : feedBack.data.cacheError ? (
    <JsonView
      src={feedBack.data.cacheError}
      style={{ width: "30vw" }}
      name="cache"
      displayDataTypes={false}
      displayObjectSize={false}
      enableClipboard={false}
      theme="apathy"
    />
  ) : (
    <Space direction="vertical">
      <JsonView
        style={{ width: "30vw" }}
        src={feedBack.data.server}
        name="server"
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        theme="apathy"
      />
      <Typography.Paragraph
        copyable={{ text: JSON.stringify(feedBack.data.server) }}
      >
        Get Data
      </Typography.Paragraph>
      {feedBack.data.server && (
        <>
          <DatePicker
            value={date}
            onChange={setDate}
            disabledDate={(x) =>
              !(x.format("YYYY-MM-DD") in feedBack.data.server)
            }
          />
          {date && (
            <>
              <Typography.Paragraph
                copyable={{
                  text: JSON.stringify(
                    feedBack.data.server[date.format("YYYY-MM-DD")].slice(0, 50)
                  ),
                }}
              >
                Get first 50
              </Typography.Paragraph>
              {feedBack.data.server[date.format("YYYY-MM-DD")].length > 50 && (
                <Typography.Paragraph
                  copyable={{
                    text: JSON.stringify(
                      feedBack.data.server[date.format("YYYY-MM-DD")].slice(
                        50,
                        100
                      )
                    ),
                  }}
                >
                  Get first 50 - 100
                </Typography.Paragraph>
              )}
              {feedBack.data.server[date.format("YYYY-MM-DD")].length > 100 && (
                <Typography.Paragraph
                  copyable={{
                    text: JSON.stringify(
                      feedBack.data.server[date.format("YYYY-MM-DD")].slice(
                        100,
                        150
                      )
                    ),
                  }}
                >
                  Get first 100 - 150
                </Typography.Paragraph>
              )}
              {feedBack.data.server[date.format("YYYY-MM-DD")].length > 150 && (
                <Typography.Paragraph
                  copyable={{
                    text: JSON.stringify(
                      feedBack.data.server[date.format("YYYY-MM-DD")].slice(
                        150,
                        200
                      )
                    ),
                  }}
                >
                  Get first 150 - 200
                </Typography.Paragraph>
              )}
            </>
          )}
        </>
      )}
    </Space>
  );
};

export default PartnerWithUs;
